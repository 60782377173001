define('flashAssets', ['jquery', 'siteObj'], function($, siteObj) {
  
  // show the trailer
  if (window.showExtras) {
    // add trailer
    var videowidth = window.strTrailerWidth;
    var videoheight = 271;
    // decode the url as it's being output as escaped text
    var theVidURL = decodeURIComponent(window.theVideoFile);
    var videoId = document.getElementById(window.videodivid);

    if (videoId !== null) {

      $(videoId).flash({
        swf: siteObj.siteCDNPath + '/design-assets/flash/trailer-assets/playerv2.swf',
        height: videoheight,
        width: videowidth,
        expressInstaller: siteObj.siteCDNPath + '/design-assets/flash/trailer-assets/expressinstall.swf',
        flashvars: {
          repeat: window.theVideoRepeat,
          vpid: 'ae6e3934-e2d5-48ac-b6ce-7569cb758deb',
          file: theVidURL,
          image: window.theVideoImage + '?v=1',
          skin: siteObj.siteCDNPath + '/design-assets/flash/trailer-assets/bright.swf',
          backcolor: window.theVideoBackColour,
          frontcolor: window.theVideoFrontColour,
          lightcolor: window.theVideoLightColour,
          stretching: 'none',
          // Don't include the plugins in IE6 for the time being as this is causing a JS error
          playlist: 'none'
        },
        params: {
          allowfullscreen: true,
          allowscriptaccess: 'always',
          wmode: 'transparent'
        }
      });
    }
  }
});
